*, *:before, *:after {
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
}

:root{
	--cor1: #F0D6FF;
    --cor2: #57529F;
    --cor3: #4B7D1E;
    --corHover: #B191C3;
}

.container {
    width: 90%;
    margin: 0 auto;
    padding: 0px;
}

.container2 {
    width: 80%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
}

.session {
    margin-top: 100px;
}

h1{
    font-family: 'Inria Serif', serif;
    font-size: 26px;
    font-weight: normal;
}

h2{
    font-family: 'Inria Serif', serif;
    font-size: 20px;
    font-weight: normal;
}

h3{
    font-family: 'Amiko', sans-serif;
    font-size: 12px;
    font-weight: normal;
}

p{
    font-family: 'Amiko', sans-serif;
    font-size: 14px;
    font-weight: normal;
}

.bold{
    font-weight: bold;
}

button{
    cursor: pointer;
    background: transparent;
    border: none;
    transition: color 0.2s;
    padding: 0px;
}

.change{
    transition: 0.5s ease-in;
}

img[data-src]{
    opacity: 0;
}

img[src]{
    opacity: 1;
    transition: 0.5s ease-in;
}

a{
    cursor: pointer;
    background: transparent;
    border: none;
    transition: color 0.2s;
    padding: 0px;
    text-decoration: none;
    color: black;
    display: inline-block
}

.noOverflow{
    overflow-y:hidden;
}
.menu {
    width: 35px;
    height: 3px;
    background-color: black;
    margin: 6px 0;
}

.menuS{
    cursor: pointer;
    z-index: 2;
}

.responsiveSandwich{
    display: none;
    align-items: center;
    justify-content: space-between;
    gap: 150px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 4px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  /* Rotate first bar */
  .change .bar1 {
    transform: translate(0, 10px) rotate(-45deg);
  }
  
  /* Fade out the second bar */
  .change .bar2 {opacity: 0;}
  
  /* Rotate last bar */
  .change .bar3 {
    transform: translate(0, -10px) rotate(45deg);
  }

.navbarFixed{
    position: fixed !important;
    z-index: 1;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.navbarFixed img{
    width: auto;
    height: 50px !important;
}

.buttonNav:hover{
    color: var(--corHover);
}

.buttonBody{
    background-color: var(--cor2);
    color: white;
    padding: 15px 30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    transition-timing-function: ease-in;
    transition: color 0.2s, background-color 0.2s;
}

.buttonBody:hover{
    background-color: var(--corHover);
}

.notVisible{
    opacity: 0 !important;
    visibility: hidden !important;
}

.hidden{
    opacity: 0 !important;
    visibility: hidden !important;
    position: absolute;
}

.sepH{
    width: 70px;
    background-color: black;
    height: 2px;
    margin-top: 5px;
}

.footerFa{
    color: #57529F;
}

.part6Fa{
    transform: rotate(180deg);
}

.fa{
    font-size: 23px;
    transition-timing-function: ease-in;
    transition: color 0.2s, background-color 0.2s, transform 0.2s;
}

.buttonFooterFa{
    transition-timing-function: ease-in;
    transition: color 0.2s, background-color 0.2s;
}

.buttonFooterFa:hover{
    background-color: #B191C3 !important;
}

.buttonFooterFa:hover
.fa {
    color: white !important;
}

.buttonFooter{
    margin-top: 10px;
    background-color: white;
    color: #57529F;
    padding: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    transition-timing-function: ease-in;
    transition: color 0.2s, background-color 0.2s;
    width: 55%;
}

.buttonFooter:hover{
    color: white;
    background-color: #B191C3;
}

.buttonFAQ{
    background-color: #57529F !important;
    color: white !important;
}

.buttonFAQ fa{
    background-color: #57529F !important;
    color: white !important;
    transform: rotate(180deg);
}

.buttonFAQ p{
    color: white !important;
}

.part6DivContent{
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s, visibility 0.2s;
}

.backgroundImage{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.atendimentosBackgroundImage1{
    background-image: url('../../public/img/foto bruna.png');
}

.atendimentosBackgroundImage2{
    background-image: url('../../public/img/foto bruna.png');
}

.homePagePart1BackgroundImage{
    background-image: url('../../public/img/bruna-foto1.jpg');
}

.homePagePart3BackgroundImage{
    background-image: url('../../public/img/bruna-foto2.jpg');
}

.homePagePart4BackgroundImage1{
    background-image: url('../../public/img/bruna-foto3.jpg');
}

.homePagePart4BackgroundImage2{
    background-image: url('../../public/img/bruna-foto6.jpg');
}

.homePagePart4BackgroundImage3{
    background-image: url('../../public/img/bruna-foto7.jpg');
}

.homePagePart5BackgroundImage1{
    background-image: url('../../public/img/bruna-foto4.jpg');
}

.homePagePart5BackgroundImage2{
    background-image: url('../../public/img/bruna-foto5.jpg');
}

@media only screen and (max-width: 869px){
    
    .responsiveSandwich{
        display: flex !important;
    }
    .responsiveNavbar{
        display: none !important;
    }
    .responsivePart1Content{
        display: flex  !important;
        align-items: center !important;
        justify-content: space-around !important;
        flex-direction: column !important;
        padding: 40px !important;
        height: 100vh;
    }
    .responsivePart1ImgText{
        display: block !important;
        padding-top: 80px !important;
    }
    .responsivePart1Text{
        width: 100% !important;
    }

    .responsivePart3Div1{
        width: 100% !important;
        float: none !important;
    }
    .responsivePart3Text{
        margin-top: -80px !important;
        width: 100% !important;
        height: auto !important;
        float: none !important;
    }

    .responsivePart4Div1{
        width: 10% !important;
        margin-top: 0px !important;
        height: 380px !important;
    }
    .responsivePart4Text{
        float: none !important;
        margin-top: 0px !important;
        width: 100% !important;
    }
    .responsivePart4{
        margin-top: 90px !important;
        padding-top: 0px !important;
        gap: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .responsivePart5Content{
        height: auto !important;
        padding: 30px;
    }
    .responsivePart5Text{
        margin-top: 0px !important;
    }

    .responsiveInstagram{
        margin: 90px 0px 0px 0px !important;
        height: auto !important;
    }
    .responsiveInstagramContent{
        display: block !important;
    }
    .responsiveInstagramText{
        width: 100% !important;
    }
    .responsiveInstagramFeedBackground{
        display: none !important;
    }
    .responsiveInstagramFeedImages{
        border: none !important;
        padding: 0px !important;
        width: 100% !important;
    }
    .responsiveInstagramImages{
        height: auto !important;
    }
    .responsiveAtendimentosPart1{
        display: block !important;
    }
    .responsiveAtendimentosPart1TextLeft{
        width: auto !important;
    }

    .responsiveImg{
        display: none !important;
    }

    .responsivePart4Div1{
        margin-bottom: 0px !important;
    }
    .responsivePart4Content{
        padding-top: 0px !important;
    }
    .responsivePart4Background{
        display: none !important;
    }

}

@media only screen and (min-width: 870px) and (max-width: 1257px){

    .responsiveNavbar{
        gap: 50px !important;
    }
    .responsiveInstagram{
        margin: 300px 0px !important;
    }
    .responsiveInstagramFeedBackground{
        height: 680px !important;
        margin-bottom: -683px !important;
    }
    .responsiveInstagramImages{
        height: 680px !important;
    }

    .responsivePart3Text{
        margin-top: -625px !important;
        height: 740px !important;
    }

    .responsivePart4Content{
        padding-top: 940px !important;
    }

}